import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  addDeliveryAreas,
  changeDeliveryStatus,
  getDeliveryStatus,
  getDeliveryPrioritization,
  updateDeliveryPrioritization
} from '../../firebase-logic/deliveryArea';
import useAuthState from '../../global-states/useAuthState';
import { DeliveryDataObject } from '../../types';
import AddDeliveryAreaModal from './AddDeliveryAreaModal';
import DeliveryAreaCard from './DeliveryAreaCard';
import DeliveryInfo from './DeliveryInfo';
import DeliveryPrioritizationModal from './DeliveryPrioritizationModal';
import EditIcon from 'ui-components/EditIcon';

type Props = {
  deliveryAreas: DeliveryDataObject[];
  triggerEdit: Function;
  setDeliveryAreas: Function;
};

function DeliveryAreaList(props: Props) {
  const auth: any = useAuthState.getState();
  const { t } = useTranslation(['delivery']);
  const { deliveryAreas, triggerEdit, setDeliveryAreas } = props;
  const [isDeliveryEnabled, setIsDeliveryEnabled] = useState(false);
  const [openAddDeliveryAreaModal, setOpenAddDeliveryAreaModal] = useState(false);
  const [selectedDeliveryArea, setSelectedDeliveryArea] = useState<DeliveryDataObject>({} as DeliveryDataObject);
  
  // New state for delivery prioritization
  const [deliveryPrioritization, setDeliveryPrioritization] = useState(4); // Default to balanced (4)
  const [isPrioritizationModalOpen, setIsPrioritizationModalOpen] = useState(false);

  useEffect(() => {
    setDeliveryStatus();
    fetchDeliveryPrioritization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDeliveryStatus = async () => {
    const deliveryStatus = await getDeliveryStatus(auth.user);
    setIsDeliveryEnabled(deliveryStatus);
  };
  
  // New function to fetch the delivery prioritization setting
  const fetchDeliveryPrioritization = async () => {
    try {
      const prioritization = await getDeliveryPrioritization(auth.user);
      if (prioritization !== undefined) {
        setDeliveryPrioritization(prioritization);
      }
    } catch (error) {
      console.error('Error fetching delivery prioritization:', error);
    }
  };
  
  // Function to handle saving the delivery prioritization
  const handleSaveDeliveryPrioritization = async (newPrioritization: number) => {
    try {
      await updateDeliveryPrioritization(auth.user, newPrioritization);
      setDeliveryPrioritization(newPrioritization);
      toast.success(t('prioritization_updated.label', 'Delivery prioritization updated successfully'));
    } catch (error) {
      console.error('Error updating delivery prioritization:', error);
      toast.error(t('prioritization_update_error.label', 'Failed to update delivery prioritization'));
    }
  };
  
  // Function to get the label for current prioritization level
  const getPrioritizationLabel = (level: number) => {
    switch(level) {
      case 1:
        return t('prioritization_level_1.label', 'Individual Delivery');
      case 2:
        return t('prioritization_level_2.label', 'Minimal Batching');
      case 3:
        return t('prioritization_level_3.label', 'Light Batching');
      case 4:
        return t('prioritization_level_4.label', 'Balanced');
      case 5:
        return t('prioritization_level_5.label', 'Efficient Batching');
      case 6:
        return t('prioritization_level_6.label', 'High Efficiency');
      case 7:
        return t('prioritization_level_7.label', 'Maximum Efficiency');
      default:
        return '';
    }
  };

  const handleEdit = () => {
    triggerEdit();
  };

  const toggle = () => {
    changeDeliveryStatus(auth.user, !isDeliveryEnabled);
    setIsDeliveryEnabled(!isDeliveryEnabled);
  };

  const handleUpdateDeliveryArea = async (
    updatedDeliveryArea: DeliveryDataObject
  ) => {
    let tempAllDeliveryAreas = [...deliveryAreas];
    tempAllDeliveryAreas[
      tempAllDeliveryAreas.findIndex(
        (area) => area.id === updatedDeliveryArea.id
      )
    ] = updatedDeliveryArea;

    toast.promise(updateDeliveryAreas(tempAllDeliveryAreas, false), {
      loading: t('updating.label'),
      success: t('updateSuccess.label'),
      error: t('somethingWentWrong.label'),
    });
  };

  const handleCancel = () => {};

  const handleEditClick = (deliveryArea: DeliveryDataObject) => {
    setSelectedDeliveryArea(deliveryArea);
    setOpenAddDeliveryAreaModal(true);
  };

  const handleDelete = (areaId: string) => {
    let filteredDeliveryAreas = deliveryAreas.filter(
      (area) => area.id !== areaId
    );
    toast.promise(updateDeliveryAreas(filteredDeliveryAreas, true), {
      loading: t('deleting.label'),
      success: t('deliveryAreaAdded.label'),
      error: t('somethingWentWrong.label'),
    });
  };

  const updateDeliveryAreas = async (
    updatedDeliveryAreas: DeliveryDataObject[],
    isDeleted: boolean
  ) => {
    try {
      await addDeliveryAreas(auth.user, [...updatedDeliveryAreas]);
      setDeliveryAreas([...updatedDeliveryAreas]);
    } catch (error) {
      console.log('error in saving', error);
    }
  };

  return (
    <div className='flex flex-col p-5'>
      <DeliveryInfo
        isDeliveryEnabled={isDeliveryEnabled}
        setIsDeliveryEnabled={toggle}
        isDeliveryAreaEmpty={deliveryAreas.length === 0}
      />

      <hr className='mt-6 mb-4' />
      
      {/* Add Delivery Prioritization Section */}
      <div className="mb-4">
        <h1 className='text-lg font-medium mb-3'>{t('delivery_prioritization.label', 'Deliveries batching')}</h1>  
        <div 
          className="px-3 py-4 border border-gray-200 rounded-lg cursor-pointer hover:bg-gray-100 transition ease-in-out"
          onClick={() => setIsPrioritizationModalOpen(true)}
        >
          <div className="flex justify-between">
            <div>
              <h2 className="font-medium">
                {getPrioritizationLabel(deliveryPrioritization)}
              </h2>
              <p className="text-sm text-gray-500 mt-1">
                {t('click_to_adjust.label', 'Click to adjust batching preferences')}
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <h1 className='text-lg font-medium mt-5 mb-2'>
        {t('delivery_area.label')}
      </h1>
      {deliveryAreas.length > 0 && (
        <div>
          {deliveryAreas.map((area) => (
            <DeliveryAreaCard
              onEditClicked={handleEditClick}
              deliveryAreaDetails={area}
              key={area.id}
            />
          ))}
        </div>
      )}
      <div className='flex flex-row justify-center mt-2'>
        <button
          type='button'
          className='relative_add_button w-56 h-10'
          onClick={handleEdit}
        >
          + {t('addnewarea.button')}
        </button>
      </div>
      
      {/* Add the prioritization modal */}
      <DeliveryPrioritizationModal
        isOpen={isPrioritizationModalOpen}
        onClose={() => setIsPrioritizationModalOpen(false)}
        currentPrioritization={deliveryPrioritization}
        onSave={handleSaveDeliveryPrioritization}
      />
      
      <AddDeliveryAreaModal
        openAddDeliveryAreaModal={openAddDeliveryAreaModal}
        closeAddDeliveryAreaModal={setOpenAddDeliveryAreaModal}
        handleAddDeliveryArea={handleUpdateDeliveryArea}
        handleCancel={handleCancel}
        isUpdate={true}
        selectedDeliveryZone={selectedDeliveryArea}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default DeliveryAreaList;