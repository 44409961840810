import React, { useEffect, useState } from "react";
import DeliveryMap from "../ui-components/delivery-components/DeliveryMap";
import DeliveryAreaList from "../ui-components/delivery-components/DeliveryAreaList";
import useAuthState from "../global-states/useAuthState";
import { getDeliveryAreas } from "../firebase-logic/deliveryArea";
import { DeliveryDataObject } from "../types";

export default function DeliveryScreen() {
  const auth: any = useAuthState.getState();
  const [isEditing, setIsEditing] = useState(false);
  const [deliveryAreas, setDeliveryAreas] = useState<DeliveryDataObject[]>([]);
  const triggerEdit = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    getAllDeliveryAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllDeliveryAreas = async () => {
    const allDeliveryAreas = await getDeliveryAreas(auth.user);
    setDeliveryAreas(allDeliveryAreas ? allDeliveryAreas : []);
  };
  return (
    <div className="flex w-full h-full flex-wrap">
      <div className="w-full h-2/3 sm:h-full sm:w-3/4">
        <DeliveryMap
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          deliveryAreas={deliveryAreas}
          setDeliveryAreas={setDeliveryAreas}
        />
      </div>
      <div className="w-full sm:w-1/4 max-h-full bg-white overflow-y-auto">
        <DeliveryAreaList
          deliveryAreas={deliveryAreas}
          setDeliveryAreas={setDeliveryAreas}
          triggerEdit={triggerEdit}
        />
      </div>
    </div>
  );
}
