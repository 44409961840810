import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { DeliveryDataObject } from '../../types';
import CustomInputField from '../CustomInputField';
import { v4 as uuidv4 } from 'uuid';
import ColorPicker from './ColorPicker';
import InputFieldWithType from '../InputFieldWithType';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

type Props = {
  openAddDeliveryAreaModal: boolean;
  closeAddDeliveryAreaModal: Function;
  handleAddDeliveryArea: Function;
  handleCancel: Function;
  isUpdate?: boolean;
  selectedDeliveryZone?: DeliveryDataObject;
  handleDelete?: Function;
};

interface FormField {
  label: string;
  name: string;
  value: string | number;
  type: string;
}
function AddDeliveryAreaModal(props: Props) {
  const {
    openAddDeliveryAreaModal,
    closeAddDeliveryAreaModal,
    handleAddDeliveryArea,
    handleCancel,
    isUpdate,
    selectedDeliveryZone,
    handleDelete,
  } = props;
  const cancelButtonRef: any = useRef();

  const { t } = useTranslation(['delivery']);

  const [areaColor, setAreaColor] = useState('#0ea5e9');
  const [formFields, setFormFields] = useState<FormField[]>([
    {
      label: t('editarea_areaname.label'),
      name: 'name',
      value: '',
      type: 'text',
    },
    {
      label: t('editarea_deliveryfee.label'),
      name: 'deliveryFee',
      value: 0,
      type: 'number',
    },
    {
      label: t('editarea_minimumorder.label'),
      name: 'minOrder',
      value: 0,
      type: 'number',
    },
  ]);

  useEffect(() => {
    if (isUpdate && selectedDeliveryZone) {
      updateFormFields(selectedDeliveryZone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, selectedDeliveryZone]);

  const updateFormFields = (deliveryArea: DeliveryDataObject) => {
    let tempFormFields = [...formFields];
    tempFormFields[0].value = deliveryArea.name;
    tempFormFields[1].value = deliveryArea.deliveryFee;
    tempFormFields[2].value = deliveryArea.minOrder;
    setFormFields([...tempFormFields]);
    setAreaColor(deliveryArea.color);
  };
  const cancel = () => {
    handleCancel();
    closeAddDeliveryAreaModal(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let deliveryAreaDetails: DeliveryDataObject = {
      id: selectedDeliveryZone ? selectedDeliveryZone.id : uuidv4(),
      name: formFields[0].value as string,
      deliveryFee: parseFloat(formFields[1].value as string),
      minOrder: parseFloat(formFields[2].value as string),
      color: areaColor,
      path: selectedDeliveryZone ? selectedDeliveryZone.path : [],
    };
    // @ts-ignore
    toast.promise(handleAddDeliveryArea(deliveryAreaDetails), {
      loading: t('saving.label'),
      // @ts-ignore
      success: t('areaAdded.label'),
      error: t('couldNotSaved.label'),
    });
    cancel();
  };

  const handleInputChange = (name: any, value: any, index: number) => {
    let tempState = [...formFields];
    let tempElement = { ...tempState[index] };
    tempElement.value = value;
    tempState[index] = tempElement;
    setFormFields(tempState);
  };

  return (
    <Transition.Root show={openAddDeliveryAreaModal} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-30 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={openAddDeliveryAreaModal}
        onClose={cancel}
      >
        <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity w-120' />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <form
              onSubmit={(e) => handleSubmit(e)}
              className='p-6 inline-block align-bottom bg-white  rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg'
            >
              <h1 className=' text-lg flex items-center'>
                <b>{isUpdate ? t('editarea.label') : 'Create area'}</b>
              </h1>
              <p className=' text-gray-500 mt-2 mb-8'>{t('editarea.text')}</p>
              <div className='flex flex-row justify-between'>
                <div className='flex flex-col justify-between'>
                  {formFields &&
                    formFields.length > 0 &&
                    formFields.map((field: any, i: any) => {
                      return (
                        <div key={i}>
                          <b className='text-md'> {field.label}</b>
                          <div className='w-32 mt-2 sm:w-48 mb-3'>
                            {field.name === 'name' ? (
                              <CustomInputField
                                classnameadditional='h-10 placeholder-italic'
                                name={field.name}
                                type={field.type}
                                value={field.value}
                                step='0.01'
                                id={field.name}
                                onChange={(e: any) =>
                                  handleInputChange(
                                    field.name,
                                    e.target.value,
                                    i
                                  )
                                }
                                required={true}
                              />
                            ) : (
                              <InputFieldWithType
                                name={field.name}
                                type={field.type}
                                value={field.value}
                                id={field.name}
                                onInputFieldValueChanged={(e: any) => {
                                  handleInputChange(field.name, e, i);
                                }}
                                required={true}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className='ml-10'>
                  <div className='flex flex-row '>
                    <b className='text-md mr-4'>
                      {t('editarea_selectedcolor.label')}
                    </b>
                    <div
                      style={{ backgroundColor: areaColor }}
                      className='w-6 h-6 rounded-full'
                    />
                  </div>
                  <ColorPicker
                    selectedColor={areaColor}
                    showName={false}
                    onColorSelected={(newColorHex: string) => {
                      setAreaColor(newColorHex);
                    }}
                  />
                </div>
              </div>

              <div className='flex justify-between'>
                {isUpdate && selectedDeliveryZone && handleDelete && (
                  <button
                    type='button'
                    className='mt-6 relative_red_button w-24 h-9'
                    onClick={() => {
                      handleDelete(selectedDeliveryZone.id);
                      cancel();
                    }}
                  >
                    {t('delete.button')}
                  </button>
                )}
                <div className='mt-6 flex items-center justify-between'>
                  <div className='flex justify-end'>
                    <button
                      type='button'
                      className='cancel_button h-9 w-24 mr-4'
                      onClick={cancel}
                    >
                      {t('cancel.button')}
                    </button>
                    <button
                      className='relative_blue_button h-9 w-24'
                      type='submit'
                    >
                      {t('save.button')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AddDeliveryAreaModal;
