import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  currentPrioritization: number;
  onSave: (prioritization: number) => void;
};

function DeliveryPrioritizationModal(props: Props) {
  const { isOpen, onClose, currentPrioritization, onSave } = props;
  const cancelButtonRef = useRef(null);
  const { t } = useTranslation(['delivery']);
  const [prioritization, setPrioritization] = useState(currentPrioritization);

  useEffect(() => {
    setPrioritization(currentPrioritization);
  }, [currentPrioritization]);

  const handleSave = () => {
    onSave(prioritization);
    onClose();
  };

  const getPrioritizationDescription = (level: number) => {
    switch(level) {
      case 1:
        return t('prioritization_level_1_desc.label', 'No delivery batching at all. Every order must be delivered separately to every customer.');
      case 2:
        return t('prioritization_level_2_desc.label', 'Limited batching for very close orders. Orders will only be batched if they are very close to each other and have similar delivery times.');
      case 3:
        return t('prioritization_level_3_desc.label', 'Light batching prioritizing customer satisfaction. Orders that are on the same route may be combined.');
      case 4:
        return t('prioritization_level_4_desc.label', 'Balanced approach between speed and efficiency. The system will make reasonable batching decisions.');
      case 5:
        return t('prioritization_level_5_desc.label', 'Moderate batching favoring efficiency. The system will actively look for opportunities to combine orders to be delivered together.');
      case 6:
        return t('prioritization_level_6_desc.label', 'High efficiency batching. Multiple orders will be combined when possible. Some customers may experience longer wait times.');
      case 7:
        return t('prioritization_level_7_desc.label', 'Maximum efficiency. The system will prioritize combining as many orders as possible in a single trip, potentially with longer waiting times for customers.');
      default:
        return '';
    }
  };

  const getPrioritizationLabel = (level: number) => {
    switch(level) {
      case 1:
        return t('prioritization_level_1.label', 'No batching');
      case 2:
        return t('prioritization_level_2.label', 'Minimal batching');
      case 3:
        return t('prioritization_level_3.label', 'Light batching');
      case 4:
        return t('prioritization_level_4.label', 'Balanced');
      case 5:
        return t('prioritization_level_5.label', 'Efficient batching');
      case 6:
        return t('prioritization_level_6.label', 'High efficiency');
      case 7:
        return t('prioritization_level_7.label', 'Maximum efficiency');
      default:
        return '';
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-30 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={isOpen}
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity w-120" />
          </Transition.Child>
          
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="p-6 inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
              <h1 className="text-lg flex items-center">
                <b>{t('delivery_prioritization.label', 'Delivery batching')}</b>
              </h1>
              <p className="text-gray-500 mt-2 mb-8">
                {t('delivery_prioritization_description.label', 'Adjust how orders are batched for delivery. Lower values prioritize speed, higher values prioritize efficiency.')}
              </p>
              
              <div className="flex flex-col mb-8">
                <div className="mb-5 font-medium text-center mx-auto text-white text-md bg-gray-700 rounded-lg py-1 px-3">
                  {getPrioritizationLabel(prioritization)}
                </div>
                <div className="w-full">
                  <input
                    type="range"
                    min="1"
                    max="7"
                    step="1"
                    className="range-style w-full"
                    value={prioritization}
                    onChange={(e) => setPrioritization(parseInt(e.target.value))}
                  />
                  <div className="flex justify-between text-sm text-gray-600 px-1 mt-2">
                    <span>{t('prioritize_speed.label', 'Prioritize speed')}</span>
                    <span>{t('prioritize_efficiency.label', 'Prioritize efficiency')}</span>
                  </div>
                </div>
                
                <div className="mt-6 p-3 bg-gray-100 rounded-lg">
                  <p className="text-sm text-gray-900">
                    {getPrioritizationDescription(prioritization)}
                  </p>
                </div>
              </div>
              
              <div className="mt-6 flex items-center justify-end">
                <button
                  type="button"
                  className="cancel_button h-9 w-24 mr-4"
                  onClick={onClose}
                  ref={cancelButtonRef}
                >
                  {t('cancel.button', 'Cancel')}
                </button>
                <button
                  className="relative_blue_button h-9 w-24"
                  onClick={handleSave}
                >
                  {t('save.button', 'Save')}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default DeliveryPrioritizationModal;