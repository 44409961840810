import { DeliveryDataObject } from "../types";
import db from "./firebase";


// TODO: Migrate all delivery related settings to a separate document and adjsut these calls accordingly. All of this info is now in a single document. Calling it again is redundant.

export const addDeliveryAreas = async (
  user: any,
  deliveryAreas: DeliveryDataObject[]
) => {
  try {
    await db
      .collection("companies")
      .doc(user)
      .update({ deliveryAreas: deliveryAreas });
    return;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getDeliveryAreas = async (user: any) => {
  const val = await db.collection("companies").doc(user).get();
  const deliveryAreas: DeliveryDataObject[] = val.get("deliveryAreas");

  return deliveryAreas;
};


// TODO: Migrate all delivery related settings to a separate document and adjsut these calls accordingly. All of this info is now in a single document. Calling it again is redundant.
// Get the delivery prioritization setting for a company
export const getDeliveryPrioritization = async (companyId: string): Promise<number> => {
  try {
    const companyRef = db.collection('companies').doc(companyId);
    const snapshot = await companyRef.get();
    
    if (snapshot.exists) {
      const data = snapshot.data();
      // Return the prioritization setting or default to 4 (balanced)
      return data?.deliveryPrioritization || 4;
    }
    
    return 4; // Default to balanced if not found
  } catch (error) {
    console.error('Error getting delivery prioritization:', error);
    throw error;
  }
};

// Update the delivery prioritization setting for a company
export const updateDeliveryPrioritization = async (
  companyId: string, 
  prioritization: number
): Promise<void> => {
  try {
    const companyRef = db.collection('companies').doc(companyId);
    
    await companyRef.update({
      deliveryPrioritization: prioritization,
    });
  } catch (error) {
    console.error('Error updating delivery prioritization:', error);
    throw error;
  }
};


export const changeDeliveryStatus = async (
  user: any,
  deliveryStatus: boolean
) => {
  try {
    await db
      .collection("companies")
      .doc(user)
      .update({ delivery: deliveryStatus });
    return;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getDeliveryStatus = async (user: any) => {
  const val = await db.collection("companies").doc(user).get();
  const deliveryStatus: boolean = val.get("delivery");

  return deliveryStatus;
};